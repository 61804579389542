<template>
	<div class="news-list">
		<!-- 头部 -->
		<mainHeader></mainHeader>
		<!-- 导航 -->
		<navigation>
			<template slot="breadcrumb">
				<breadcrumb title="资讯列表" routerPath="/newslist"></breadcrumb>
			</template>
		</navigation>
		<div class="wrapper news-list-con clearfix">
			<div class="news-list-left">
				<el-empty :image-size="50" description="暂无资讯" v-if="newsList.data.length==0"></el-empty>
				<ul>
					<li v-for="(item,index) in newsList.data" :key="index" class="news-list-item clearfix">
						<router-link :to="{ path: '/newsdetails', query: { id: item.article_id }}">
							<img :src="item.image_url" alt="">
							<div class="content">
								<p class="title">{{item.title}}</p>
								<!-- <p class="tips">{{item.tips}}</p> -->
								<p class="see"><i class="el-icon-view"></i>
									<span>{{item.show_views}}人浏览</span>
								</p>
							</div>
						</router-link>
					</li>
				</ul>
				<!-- 分页 -->
				<div class="paging">
					<el-pagination @current-change="handleCurrentChange" background
						:current-page.sync="currentPage" :page-size="100" layout="prev, pager, next, jumper"
						:total="newsList.total">
					</el-pagination>
				</div>
			</div>
			<div class="news-list-right">
				<hotSale></hotSale>
			</div>
		</div>
		<!-- 尾部 -->
		<mainFooter></mainFooter>
	</div>
</template>

<script>
	import * as ArticleApi from '@/api/article';
	import mainHeader from '../../components/header.vue'
	import navigation from '../../components/navigation.vue'
	import breadcrumb from '../../components/breadcrumb.vue'
	import mainFooter from '../../components/footer.vue'
	import hotSale from '../product-details/components/hot-sale.vue'
	export default {
		components: {
			mainHeader,
			navigation,
			breadcrumb,
			mainFooter,
			hotSale
		},
		data() {
			return {
				newsList: [],
				page: 1,
				currentPage:1
			};
		},
		created() {
			this.getList()
		},
		methods: {
			getList() {
				let app = this
				ArticleApi.list({
					page: app.page
				}).then((result) => {
					// console.log(result)
					app.newsList = result.data.list
				})
			},
			handleCurrentChange(val){
				this.page = val
				this.getList()
			}
		}

	}
</script>

<style lang="scss">
	@import "../../assets/css/news.scss";
	@import "../../assets/css/product-details.scss"
</style>
